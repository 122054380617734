import * as statusMapper from "@/service/error_request_mapper.js";

export class CollaboratorPractitionerService {
  constructor(api) {
    this.api = api;
  }

  /** Obtient un praticien  */
  async getById(id) {
    return this.api.getById(id).then((result) => {
      console.debug('reutrn of get by id api collaborator-practitionner : ' + JSON.stringify(result))
      return result;
    }).catch((error) => {
      console.error("Error on service users - collaborator" + error.message);
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Fait appel à l'api pour savoir si un praticien est existant en tant que collaborateur */
  async foundPractitioner(id) {
    return this.api.getById(id).catch((error) => {
      if (error.message.includes('404')) {
        return null;
      } else {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      }
    });
  }

  /** Création d'un praticien collaborateur */
  async createPractitionerCollaborator(entity) {
    return this.api.create(entity)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Suppression d'un praticien collaborateur */
  async deletePractitionerCollaborator(uuid) {
    return this.api.delete(uuid)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(uuid);
      converter.convert(error);
    });
  }
}