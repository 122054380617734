import * as statusMapper from "@/service/error_request_mapper.js";

export class CollaboratorsService {
  constructor(api) {
    this.api = api;
  }

  async searchCollaboratorByEmail(search, timestamp) {
    return this.api
      .incrementalSearchCollaboratorByField("email_principal", search, 6)
      .then((result) => {
        return {
          users: result.users,
          timestamp,
        };
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

    /** Récupère un collaborateur par son uuid */
  async getByUuid(uuid) {
    return this.api.getByUuid(uuid).catch((error) => {
      console.error("Error on service users - collaborator" + error.message);
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }
}