/** 
 * Convertit les types de praticiens reçu de l'api en type de praticiens lisible.
 */
 export function convertPractitionerType(value) {
  if (!value) {
    return "";
  }

  switch(value) {
    case "COLLABORATER":
      return "COLLABORATEUR";
    case "EXTERNAL":
      return "EXTERNE";
  }
}

/**
 * Convertit les genres reçus de l'api en genre lisible
 */
export function convertPractitionerGender(value) {
  if (!value) {
    return "";
  }

  switch(value) {
    case "M":
      return "Homme";
    case "F":
      return "Femme";
  }
}

/**
 * Convertit les genres reçus du front en genre pour l'api
 */
export function reversePractitionerGender(value) {
  if (!value) {
    return "";
  }

  switch(value) {
    case "Homme":
      return "M";
    case "Femme":
      return "F";
  }
}


/** L'énumération des civilité de praticien */
export function exerciseCivilitiesEnum() {
  
  let doctor = {
    value: "DR",
    name: "DR",
    view: "DR"
  };
  let professor = {
    value: "PR",
    name: "PR",
    view: "PR",
  };
  let none = {
    value: null,
    name: "Aucune",
    view: "",
  };

  let civilities = [];
  civilities.push(doctor);
  civilities.push(professor);
  civilities.push(none);

  return civilities;
}

