import * as statusMapper from "@/service/error_request_mapper.js";

export class PractitionnerService {
  constructor(api) {
    this.api = api;
  }

  /** Recherche la liste des praticiens par leur nom */
  async searchPractitionerByName(name, timestamp, max = 20) {
    return this.api.searchPractitionerByName(name, max)
    .then((result) => {
      return {
        practitioners: result.practitioners,
        timestamp
      };
    })
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Récupère un praticien par son idpp */
  async searchPractitionerByIdpp(idpp) {
    return this.api.searchPractitionerByIdpp(idpp)
    .catch((error) => {
      console.error("Error on service practitioner" + error);
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Récupère un praticien collaborateur par son idpp */
  async searchPractitionerCollaboratorByIdpp(idpp) {
    return this.api.searchPractitionerCollaboratorByIdpp(idpp)
      .catch((error) => {
        console.error("Error on service practitioner" + error);
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /** Demande à l'api si le rpps appartient à un praticien collab */
  async isPractitionerCollaboratorRpps(rpps) {
    return this.api.searchPractitionerCollaboratorByIdpp(rpps).catch((error) => {
      if (error.message.includes('404')) {
        return null;
      } else {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      }
    });
  }

  /** Récupère un lieu d'exercice en fonction d'un établissement et d'un activité */
  async getPlacticePlaceFromEstablishmentAndActivity(establishmentId, activityId) {
    return this.api.getPlacticePlaceFromEstablishmentAndActivity(establishmentId, activityId)
    .catch((error) => {
      console.error("Error on service practitioner" + error);
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }


  /** Récupère l'ensemble des noms de professions */
  async getAllProfessioNames() {
    return this.api.getAllProfessioNames()
    .catch((error) => {
      console.error("Error on service practitioner" + error);
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Récupère l'ensemble des lieux de pratiques */
  async getAllPracticePlace() {
    return this.api.getAllPracticePlace()
    .then((data) => {
      data.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });
      return data;
    })
    .catch((error) => {
      console.error("Error on service practitioner" + error);
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }


  /** Sauvegarde de l'identité du praticien */
  async saveIdentity(idpp, entity) {
    return this.api.saveIdentity(idpp, entity)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }


  /** Création d'un practicePlace pour un praticien */
  async addPracticePlace(idpp, practicePlaceId) {
    return this.api.addPracticePlace(idpp, practicePlaceId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Création des agendas yesdoc */
  async addAgendaToPracticePlace(idpp, entity) {
    return this.api.addAgendaToPracticePlace(idpp, entity)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Blaclist d'un praticien */
  async blacklistPractitioner(idpp) {
    return this.api.blacklistPractitioner(idpp)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(idpp);
      converter.convert(error);
    });
  }

  /** dé-blacklistage d'un praticien */
  async unBlacklistPractitioner(idpp) {
    return this.api.unBlacklistPractitioner(idpp)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(idpp);
      converter.convert(error);
    });
  }

  /** Delete d'un practicePlace pour un praticien */
  async deletePracticePlace(idpp, practicePlaceId) {
    return this.api.deletePracticePlace(idpp, practicePlaceId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(idpp);
      converter.convert(error);
    });
  }

  /** Delete d'un agenda yesdoc pour un praticien */
  async deleteYesdocAgenda(idpp, agnedaId) {
    return this.api.deleteYesdocAgenda(idpp, agnedaId)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(idpp);
      converter.convert(error);
    });
  }



}
